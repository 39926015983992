













































import { defineComponent, PropType, ref, computed, useRoute, watch, useContext } from '@nuxtjs/composition-api'
import { MegaMenuItemTree, useMegaMenuStore } from '@wemade-vsf/megamenu';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MegaMenuItem',
  props: {
    item: {
      type: Object as PropType<MegaMenuItemTree>,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { localePath, app: { $wm } } = useContext();
    const store = useMegaMenuStore();
    const { activeItem } = storeToRefs(store);
    const { item, level } = props
    const route = useRoute()
    const isOpen = computed(() => activeItem.value?.items_id === item.items_id)
    let openTimeout = null

    const itemClasses = computed(() => {
      return {
        open: isOpen.value,
        [`level-${level}`]: true,
        [`col-md-${item.cols_nb || 6}`]: level === 1,
        ...item.custom_class ? { [item.custom_class]: true } : {}
      }
    })

    const hasChildren = computed(() => item.children && item.children.length > 0)
    const hasSubmenu = computed(() => hasChildren.value || (item.show_subcategories && level > 0))

    const isClickable = $wm.theme.menuOpener === 'click' && level === 0 && hasChildren.value;

    const enter = () => {
      if (level > 0 || openTimeout) {
        return
      }
      openTimeout = setTimeout(() => {
        activeItem.value = item
      }, isClickable ? 100 : 300)
    }

    const leave = () => {
      if (level > 0) {
        return
      }
      if (openTimeout) {
        clearTimeout(openTimeout)
      }
      openTimeout = null
      activeItem.value = null;
    }

    watch(() => route.value,
    () => {
      leave()
    })

    const toggleMenu = (e) => {
      e.preventDefault();
      activeItem.value = isOpen.value ? null : item;
    }

    
    const handlers = isClickable
      ? { click: toggleMenu }
      : { mouseover: enter, mouseleave: leave }

    const dynamicComponent = computed(() => {
      if (isClickable) return 'button';
      if (['category', 'product', 'page', 'internal'].includes(item.link_type) && item.link) {
        return 'nuxt-link'
      } else if (item.link) {
        return 'a'
      }
      return 'span'
    })

    const componentProps = computed(() => {
      let binds = {}
      if (dynamicComponent.value === 'nuxt-link') {
        binds['to'] = localePath(`${item.link.startsWith('/') ? '' : '/'}${item.link}`)
      } else if (dynamicComponent.value === 'a') {
        binds['href'] = item.link
      }

      if (!['category', 'page'].includes(item.link_type) && item.target && item.target !== '_self') {
        binds['target'] = item.target
        binds['rel'] = 'noopener'
      }

      return binds
    })

    return {
      componentProps,
      dynamicComponent,
      hasSubmenu,
      isOpen,
      itemClasses,
      handlers,
      leave,
      activeItem
    }
  }
})
